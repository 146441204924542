import "./App.css";
import { useEffect, useRef, useState } from "react";
import "./App.css";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Welcome from "./components/Welcome/Welcome";
import Work from "./components/Work/Work";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

function App() {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);
  const [aboutShow, setAboutShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [navbarChange, setNavbarChange] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const location = useLocation();

  const getData = () => {
    fetch(`${process.env.PUBLIC_URL}/data/data.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setJsonData(myJson);
      });
  };

  useEffect(() => {
    getData();
    window.onload = function (e) {
      window.scrollTo(0, 0);
    };
  }, []);

  console.log(jsonData);

  useEffect(() => {
    if (Object.keys(jsonData).length !== 0) {
      const element = ref.current;
      let sec = element.querySelector(".app-header");
      let homeSection = element.querySelector(".home");
      let workSection = element.querySelector(".work");
      let welcomeSection = element.querySelector(".welcome");
      let contactSection = element.querySelector(".contact");

      let homeTransformOrig = () => {
        if (window.innerWidth >= 768 && window.innerWidth < 1366) {
          return "center 45%";
        } else if (window.innerWidth >= 1920) {
          return "center 45%";
        }
        return "center 40%";
      };

      let workTransformOrig = () => {
        if (window.innerWidth < 768) {
          return "center 90%";
        } else if (window.innerWidth >= 768 && window.innerWidth < 1366) {
          return "center 83%";
        } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
          return "center 86%";
        } else if (window.innerWidth >= 1920) {
          return "center 86%";
        }
        return "center 83%";
      };

      let welcomeTransformOrig = () => {
        if (window.innerWidth < 768) {
          return "center 72%";
        } else if (window.innerWidth >= 768 && window.innerWidth < 1200) {
          return "center 70%";
        } else if (window.innerWidth >= 1200 && window.innerWidth < 1366) {
          return "center 70%";
        } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
          return "center 70%";
        } else if (window.innerWidth >= 1920) {
          return "center 68%";
        }
        return "center 68%";
      };

      let masterAnim = gsap.timeline({
        scrollTrigger: {
          id: "scroll-trigger",
          trigger: sec,
          pin: true,
          start: "top top",
          end: "+=1600%",
          scrub: true,
        },
      });
      if (
        location.pathname === process.env.PUBLIC_URL + "/about" ||
        location.pathname === process.env.PUBLIC_URL + "/contact"
      ) {
        masterAnim.kill(true);
        ScrollTrigger.getById("scroll-trigger").kill(true);
        gsap.set(homeSection, { clearProps: true });
        gsap.set(workSection, { clearProps: true });
        gsap.set(welcomeSection, { clearProps: true });
        gsap.set(contactSection, { clearProps: true });
        window.scrollTo(0, 0);
      } else {
        masterAnim
          .from(element.querySelector(".home-title"), {
            y: "100vh",
            onStart: () => {
              setNavbarChange(false);
            },
          })
          .to(element.querySelector(".home-title"), {
            transform: "scale(14)",
            transformOrigin: homeTransformOrig(),
          })
          .from(workSection, {
            autoAlpha: 0,
            onStart: () => {
              setNavbarChange(true);
            },
            onReverseComplete: () => {
              setNavbarChange(false);
              homeSection.querySelector(".tilt-img").style.transform = "";
            },
          })
          .from(element.querySelector(".work-typography"), {
            y: "100vh",
          })
          .to(element.querySelector(".work-typography"), {
            transform: "scale(18)",
            transformOrigin: workTransformOrig(),
          })
          .from(welcomeSection, {
            autoAlpha: 0,
            onStart: () => {
              setNavbarChange(false);
            },
            onReverseComplete: () => {
              setNavbarChange(true);
              workSection.querySelector(".tilt-img").style.transform = "";
            },
          })
          .from(element.querySelector(".welcome-typography"), {
            y: "100vh",
          })
          .to(element.querySelector(".welcome-typography"), {
            transform: "scale(18)",
            transformOrigin: welcomeTransformOrig(),
          })
          .from(contactSection, {
            autoAlpha: 0,

            onStart: () => {
              setNavbarChange(true);
            },
            onReverseComplete: () => {
              setNavbarChange(false);
            },
          })
          .from(element.querySelector(".contact-subdetails "), {
            stagger: 1,
            autoAlpha: 0,
            onReverseComplete: () => {
              setNavbarChange(true);
            },
          });
      }
    }
  }, [location, jsonData]);

  useEffect(() => {
    if (Object.keys(jsonData).length !== 0) {
      if (location.pathname === process.env.PUBLIC_URL + "/about") {
        setAboutShow(true);
        setContactShow(false);
      } else if (location.pathname === process.env.PUBLIC_URL + "/contact") {
        setAboutShow(false);
        setContactShow(true);
      } else {
        setAboutShow(false);
        setContactShow(false);
      }
    }
  }, [location, jsonData]);

  {
    if (Object.keys(jsonData).length === 0) {
      return <div />;
    } else {
      console.log(aboutShow);
      console.log(contactShow);
      return (
        <div className="App" ref={ref}>
          <header className="app-header">
            <Navbar
              navbarToggle={navbarChange}
              currentRef={ref}
              navbarData={jsonData.navbar}
              key="navbar"
            />
            <Switch>
              <Route path={`${process.env.PUBLIC_URL}/`}>
                <div className={`${aboutShow || contactShow ? "d-none" : ""}`}>
                  <Home id="home" page1Data={jsonData.page1} key="home" />
                  <Work id="work" page2Data={jsonData.page2} key="work" />
                  <Welcome
                    id="welcome"
                    page3Data={jsonData.page3}
                    key="welcome"
                  />
                  <Contact
                    id="contact"
                    contactData={jsonData.contact}
                    key="contact"
                  />
                </div>
              </Route>
            </Switch>
          </header>
        </div>
      );
    }
  }
}

export default App;
