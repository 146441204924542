import React from "react";
import "./Contact.css";

function Contact({ contactData }) {
  return (
    <div className="section contact">
      <div className="contact-details">
        <div className="contact-image-container">
          <img
            src={`${process.env.PUBLIC_URL}/${contactData.contactImage}`}
            className="contact-image"
          />
        </div>
        <div className="contact-subdetails">
          <div className="contact-subtitle">
            <span className="contact-info">{contactData.contactInfo}</span>
            <span className="contact-address">{contactData.address}</span>
          </div>
          <div className="contact-social">
            {contactData.socialLinks.map((socialData) => (
              <a href={socialData.link} target="_blank">
                <img src={`${process.env.PUBLIC_URL}/${socialData.icon}`} />
              </a>
            ))}
          </div>
          <div className="contact-designed">
            <p>
              <a href={contactData.designed.link}>
                {contactData.designed.text}
              </a>
              <br />
              <a href={contactData.developed.link}>
                {" "}
                {contactData.developed.text}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
