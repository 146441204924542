import React from "react";
import "./Welcome.css";
import Tilty from "react-tilty";

function Welcome({ page3Data }) {
  return (
    <div className="section welcome">
      <div
        className="welcome-image-logo"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/${page3Data.bg}`,
        }}
      >
        <div className="welcome-tilt">
          <Tilty
            className="tilt-img tilt-container"
            speed={3000}
            max={45}
            reset={false}
          >
            <div className="inner-element">
              <div className="welcome-container">
                <div className="logo-typo">
                  <img
                    src={`${process.env.PUBLIC_URL}/${page3Data.logo}`}
                    className="logo-image"
                  />
                </div>
              </div>
            </div>
          </Tilty>
        </div>
        <div className="cont">
          <div className="welcome-typography">
            <span className="welcome-subtitle">{page3Data.normalColored}</span>
            <div className="welcome-title">
              <span className="welcome-title-small">{page3Data.normal}</span>
              <span className="welcome-title-large">{page3Data.bold}</span>
            </div>
          </div>
        </div>
        <div className="scroll-icon">
          <img src={`${process.env.PUBLIC_URL}/${page3Data.scrollIcon}`}/>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
