import React, { useEffect, useState } from "react";
import "./Navbar.css";

function Navbar({ navbarToggle, currentRef, navbarData }) {
  const [navMenuShow, setNavMenuState] = useState(false);
  const [logoPath, setLogoPath] = useState(navbarData.logo.whitePage);
  const [toggleBtnPath, setToggleBtnPath] = useState(navbarData.logo.whitePage);

  useEffect(() => {
    if (window.location.pathname === process.env.PUBLIC_URL + "/about") {
      let about = currentRef.current.querySelector(".aboutus");
      if (navMenuShow) {
        about.style.display = "none";
      } else {
        about.style.display = "flex";
      }
    }

    if (
      window.location.pathname === process.env.PUBLIC_URL + "/about" ||
      window.location.pathname === process.env.PUBLIC_URL + "/contact"
    ) {
      setLogoPath(navbarData.logo.darkPage);
      setToggleBtnPath(navbarData.toggle.darkPage);
    }
  }, [window.location.pathname, navMenuShow]);

  useEffect(() => {
    if (navbarToggle) {
      setLogoPath(navbarData.logo.darkPage);
      setToggleBtnPath(navbarData.toggle.darkPage);
    } else {
      setLogoPath(navbarData.logo.whitePage);
      setToggleBtnPath(navbarData.toggle.whitePage);
    }
  }, [navbarToggle]);

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-logo">
          <a
            className={`navbar-logo ${navMenuShow ? "d-none" : "d-inline"}`}
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img src={`${process.env.PUBLIC_URL}/${logoPath}`} />
          </a>
          <span>
            <img
              src={`${process.env.PUBLIC_URL}/${toggleBtnPath}`}
              className={`navbar-toggle-btn ${
                navMenuShow ? "d-none" : "d-inline"
              }`}
              onClick={() => setNavMenuState(true)}
            />
          </span>

          <span
            className={`navbar-close ${navMenuShow ? "d-inline" : "d-none"}`}
            onClick={() => setNavMenuState(false)}
          >
            <img src={`${process.env.PUBLIC_URL}/${navbarData.close}`} />
          </span>
        </div>
      </nav>
      <div className={`nav-menu ${navMenuShow ? "d-flex" : "d-none"}`}>
        <div className="nav-menuList container">
          <a
            href={`${process.env.PUBLIC_URL}/`}
            onClick={() => setNavMenuState(false)}
          >
            HOME
          </a>

          <a
            href={`${process.env.PUBLIC_URL}/AboutUs.html`}
            onClick={() => setNavMenuState(false)}
          >
            ABOUT
          </a>

          <a
            href={`${process.env.PUBLIC_URL}/contact.html`}
            onClick={() => setNavMenuState(false)}
          >
            CONTACT
          </a>
        </div>
        <div className="nav-menu-footer">
          <p>
            <a href={navbarData.designed.link}>{navbarData.designed.text}</a>
            <br />
            <a href={navbarData.developed.link}> {navbarData.developed.text}</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Navbar;
