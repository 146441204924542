import React, { useEffect, useState } from "react";
import "./Home.css";
import Tilty from "react-tilty";

function Home({ page1Data }) {
  return (
    <div className="section home">
      <div
        className="home-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/${page1Data.bg})`,
        }}
      >
        <div className="home-tilt">
          <Tilty
            className="tilt-img tilt-container"
            speed={3000}
            max={45}
            reset={false}
          >
            <div className="inner-element">
              <div className="home-container">
                <div className="home-image-logo" id="container">
                  <img
                    src={`${process.env.PUBLIC_URL}/${page1Data.logo}`}
                    className="logo-image"
                  />
                </div>
              </div>
            </div>
          </Tilty>
        </div>
        <div className="cont">
          <div className="home-title">
            <span className="home-title-subtitle">{page1Data.normal}</span>
            <span className="home-title-bold">{page1Data.bold}</span>
          </div>
        </div>
        <div className="scroll-icon">
          <img src={`${process.env.PUBLIC_URL}/${page1Data.scrollIcon}`}/>
        </div>
      </div>
    </div>
  );
}

export default Home;
