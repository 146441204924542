import React from "react";
import "./Work.css";
import Tilty from "react-tilty";

function Work({ page2Data }) {
  return (
    <div className="section work">
      <div
        className="work-image-logo"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/${page2Data.bg})`,
        }}
      >
        <div className="work-tilt">
          <Tilty
            className="tilt-img tilt-container"
            speed={3000}
            max={45}
            reset={false}
          >
            <div className="inner-element">
              <div className="work-container">
                <div className="black-logo">
                  <img
                    src={`${process.env.PUBLIC_URL}/${page2Data.logo}`}
                    className="logo-image"
                  />
                </div>
              </div>
            </div>
          </Tilty>
        </div>
        <div className="cont">
          <div className="work-typography">
            <span className="work-subtitle">{page2Data.desc}</span>
            <div className="work-title">
              <span className="work-title-small">{page2Data.normal}</span>
              <span className="work-title-large">{page2Data.bold}</span>
            </div>
          </div>
        </div>
        <div className="scroll-icon">
          <img src={`${process.env.PUBLIC_URL}/${page2Data.scrollIcon}`}/>
        </div>
      </div>
    </div>
  );
}

export default Work;
